import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import * as R from 'ramda';
import classNames from 'classnames';
import Btn from '../Btn';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './HeroStart.nordr.module.scss';
import stylesFolkhem from './HeroStart.folkhem.module.scss';

const getStyles = (theme) =>
    ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]);

class HeroStart extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentSlideIndex: null,
            nextSlideIndex: 1,
            isAnimating: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                currentSlideIndex: 0,
                nextSlideIndex: 1,
            });

            this.interval = setInterval(this.slideToNext, 8000);
        }, 600);
    }

    slideToNext = () => {
        const totalSlides = this.props.items.length;

        this.setState({
            isAnimating: true,
        });

        setTimeout(() => {
            this.setState({
                isAnimating: false,
                currentSlideIndex:
                    (this.state.currentSlideIndex + 1) % totalSlides,
            });
        }, 500);
    };

    slideTo = (index) => {
        if (index === this.state.currentSlideIndex) {
            return;
        }
        clearInterval(this.interval);

        this.setState({ currentSlideIndex: index });
    };

    render() {
        const { items } = this.props;
        let { currentSlideIndex, isAnimating } = this.state;
        const theme = this.context;
        const styles = getStyles(theme);

        const slideIndexes = R.uniq([
            currentSlideIndex,
            ...R.keys(items).map(Number),
        ]);
        return (
            <div className={styles['HeroStart']}>
                <HeroStartPagination
                    items={items}
                    currentSlide={currentSlideIndex}
                    onItemClick={this.slideTo}
                />

                <div className={styles['HeroStart__ItemsWrapper']}>
                    {slideIndexes.map((index) => {
                        return (
                            <HeroStartSlide
                                key={index}
                                {...items[index]}
                                index={index}
                                isAnimating={isAnimating}
                                active={index === currentSlideIndex}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

HeroStart.propTypes = {
    items: PropTypes.array,
};

HeroStart.defaultProps = {
    items: [],
};

HeroStart.contextType = ThemeContext;

class HeroStartPagination extends React.Component {
    handleClick = (e) => {
        this.props.onItemClick(Number(e.target.dataset.index));
    };

    render() {
        const { items, currentSlide } = this.props;
        const theme = this.context;
        const styles = getStyles(theme);

        if (items.length <= 1) {
            return null;
        }

        return (
            <div className={styles['HeroStart__PageContainer']}>
                <div className={styles['HeroStart__Page']}>
                    {items.map((_item, i) => {
                        return (
                            <span
                                data-index={i}
                                key={i}
                                className={`${styles['HeroStart__PageItem']}
                            ${
                                i === currentSlide
                                    ? styles['HeroStart__PageItem--Active']
                                    : ''
                            }`}
                                onClick={this.handleClick}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

HeroStartPagination.propTypes = {
    items: PropTypes.array,
    currentSlide: PropTypes.number,
    onItemClick: PropTypes.func,
};

HeroStartPagination.defaultProps = {
    items: [],
};

HeroStartPagination.contextType = ThemeContext;

class HeroStartSlide extends React.Component {
    state = { mounted: false };

    componentDidMount() {
        this.setState({ mounted: true });
    }

    render() {
        const { image, title, text, link, tag, active, index, isAnimating } =
            this.props;
        const theme = this.context;
        const styles = getStyles(theme);

        const img =
            image && image.renditions ? image.renditions['standard900'] : null;

        return (
            <div
                className={classNames(styles['HeroStart__ItemContainer'], {
                    [styles['HeroStart__ItemContainer--Active']]: active,
                    [styles['HeroStart__ItemContainer--Animating']]:
                        isAnimating,
                })}>
                <div className={styles['HeroStart__Item']}>
                    <div className={styles['HeroStart__ImageContainer']}>
                        <div
                            className={
                                styles[
                                    'HeroStart__ImageContainerTransitionGroup'
                                ]
                            }>
                            {img && active && (
                                <div
                                    className={styles['HeroStart__Image']}
                                    style={{
                                        backgroundImage: `url(${img.src})`,
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div className={styles['HeroStart__Body']}>
                        {typeof window !== 'undefined' && (
                            <div
                                className={classNames(
                                    styles['HeroStart__BodyContainer'],
                                    {
                                        [styles[
                                            'HeroStart__BodyContainer--Active'
                                        ]]: active,
                                    }
                                )}>
                                <div>
                                    {active && [
                                        tag && (
                                            <div
                                                key={1}
                                                className={
                                                    styles['HeroStart__Tag']
                                                }>
                                                {' '}
                                                {tag}{' '}
                                            </div>
                                        ),
                                        title && index === 0 && (
                                            <h1
                                                key={2}
                                                className={
                                                    styles['HeroStart__Title']
                                                }>
                                                {title}
                                            </h1>
                                        ),
                                        title && index !== 0 && (
                                            <h2
                                                key={2}
                                                className={
                                                    styles['HeroStart__Title']
                                                }>
                                                {title}
                                            </h2>
                                        ),
                                        text && (
                                            <p
                                                key={3}
                                                className={
                                                    styles['HeroStart__Text']
                                                }>
                                                {text}
                                            </p>
                                        ),
                                        link && link.href && link.text && (
                                            <Btn
                                                element="a"
                                                key={4}
                                                href={link.href}
                                                className={`Btn ${styles['HeroStart__Btn']}`}>
                                                {link.text
                                                    ? link.text
                                                    : i18n.t('readMore', '')}
                                            </Btn>
                                        ),
                                    ]}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

HeroStartSlide.defaultProps = {
    image: {
        renditions: {},
    },
};

HeroStartSlide.contextType = ThemeContext;

export default HeroStart;
